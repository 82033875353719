<template>
   <router-view></router-view>
</template>

<script>
// import Home from './components/Home.vue';

export default {
  name: 'App',
 components: {
   //  Home,
} 
 
}
</script>

<style  scoped>

</style>
