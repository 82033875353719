import { createApp } from 'vue'
import App from './App.vue'
import less from 'less'
import router from './router'
import { antArr } from "./vantcompoment";


const app = createApp(App).use(router);
app.use(less);
for (const item of antArr) {
    app.use(item);
  }
app.mount("#app");
